import Navbar from './components/Navbar';
import './styles/App.scss';
import './styles/Contact.scss';



function Contact() {
  return (
    <div className="App">
      <header>
        <Navbar/>      
      </header>
      <main className='contact-content'>
        <h1>Contact Fisher&apos;s Lawn and Landscape</h1>
      <p>Please use our contact form on this page to ask us about any services we could provide for you!</p>
          <iframe title="Contact Form" width="100%" height="1033" frameBorder="0" scrolling="no" src="https://www.emailmeform.com/builder/embed/330Ok5Ej6C4DfNec272cB8l"></iframe>

              


      </main>
      <footer>
        
        </footer>
    </div>
  );
}

export default Contact;
