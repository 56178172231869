import Navbar from './components/Navbar';
import './styles/App.scss';
import './styles/Home.scss';
import { Link } from 'react-router-dom'





function Home() {
  return (
    <div className="App">
      <header>
        <Navbar/>      
      </header>
      <main className='home-content'>
      <div className='first-home-image'></div>
        <div className='content-right-wrapper'>
          <div className='left-image'>
          <h1>Fisher's Lawn & Landscape, LLC.</h1>
          <h2>Licensed &amp; Insured</h2>
          </div>
          <div className='content-right'>
            <div className='content-container'>
             
              <p>Fisher's Lawn & Landscape, is a smaller company that keeps the customer in mind for giving great service, quality work and customer satisfaction, with this being said, I am at all work sites not just supervising but working with employees to complete jobs in a timely fashion so your lawn looks its best.</p>
              <p>Based out of New Haven, Indiana, we have been serving local and surrounding areas like Fort Wayne, Roanoke, Auburn, Kendallville, and Wolcottville "Since 1995".</p>
              <p>We take pride in our relationships with our customers and how your yard looks is a direct reflection on us.</p>
              <h2>Let us take care of your yard today!</h2>
              <Link to="/contact" className='button'>Contact Us Today</Link>
              <h3>Your Referral is Our Best Advertisement!</h3>
            </div>
          </div>
        </div>

      </main>
      <footer>

        </footer>
    </div>
  );
}

export default Home;
