import Navbar from './components/Navbar';
import './styles/App.scss';
import './styles/Employment.scss';



function Employment() {
  return (
    <div className="App">
      <header>
        <Navbar/>      
      </header>
      <main className='employment-content'>
        <h1>Employment</h1>
        <h2>Job requirements</h2>
        <ul>
          <li>Hardworking individuals with a positive attitude</li>
          <li>Valid driver&apos;s license with a clean driving record</li>
          <li>Ability to fill out daily job time sheets</li>
          <li>Punctuality and daily attendance</li>
          <li>Excellent communication skills with fellow employees, supervisors and customers</li>
          <li>A professional behavior</li>
          <li>Past experience in lawn mowing and landscaping - a plus</li>
        </ul>
        <h2>What we offer</h2>
        <ul>
          <li>A positive work environment</li>
          <li>Quality equipment</li>
          <li>Family focused business</li>
          <li>Uniforms - we provide a company shirt, ear and eye protection</li>
          <li>Bonus after 6 months, then a yearly bonus</li>
        </ul>
        <h3>Our online application is available if the opportunity listed above interests you.</h3>
        <iframe title="Employment Form" width="100%" height="3000" allowTransparency="true" frameBorder="0" src="https://www.emailmeform.com/builder/embed/e8173F75NLIPJBrew5d" allow="camera"></iframe>
        
      
      </main>
      <footer>
        
        </footer>
    </div>
  );
}

export default Employment;
