export const photos = [
    {
      src: "/images/landscape-large-1.jpg",
      width: 4,
      height: 3
    },
    {
        src: "/images/landscape-large-2.jpg",
        width: 4,
        height: 3
      },    
      {
        src: "/images/landscape-large-3.jpg",
        width: 4,
        height: 3
      },    
      {
        src: "/images/landscape-large-4.jpg",
        width: 4,
        height: 3
      },    
      {
        src: "/images/landscape-large-5.jpg",
        width: 4,
        height: 3
      },    
      {
        src: "/images/landscape-large-6.jpg",
        width: 4,
        height: 3
      },    
      {
        src: "/images/landscape-large-7.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/images/landscape-large-8.jpg",
        width: 4,
        height: 3
      },    {
        src: "/images/landscape-large-9.jpg",
        width: 4,
        height: 3
      },    {
        src: "/images/landscape-large-10.jpg",
        width: 4,
        height: 3
      },
      {
        src: "/images/landscape-large-11.jpg",
        width: 4,
        height: 3
      }
  ];
  