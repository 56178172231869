import { Routes, Route } from 'react-router-dom';
import Home from "./Home";
import Services from "./Services";
import Contact from "./Contact";
import GalleryPage from "./Gallery";
import Community from "./Community";
import Employment from "./Employment";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/community" element={<Community />} />
      <Route path="/employment" element={<Employment />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;
