import Navbar from './components/Navbar';
import './styles/App.scss';
import './styles/Community.scss';



function Community() {
  return (
    <div className="App">
      <header>
        <Navbar/>      
      </header>
      <main className='community-content'>
        <h1>We support our community in multiple ways</h1>
        <div className='teams'>
          <div className='team-card'>
            <img src="/images/nhba-logo.png" alt="New Haven Baseball Association Logo" />
            <p>New Haven Baseball Association</p>
          </div>
          <div className='team-card'>
            <img src="/images/nhba-majors-2011.jpg" alt="New Haven Baseball Association Majors 2011" />
            <p>2011 Minor League "Champions"</p>
          </div>
          <div className='team-card'>
            <img src="/images/nhba-majors-2012.jpg" alt="New Haven Baseball Association Majors 2012" />
            <p>2012 Major League</p>
          </div>
          <div className='team-card'>
            <img src="/images/nhba-majors-2013.jpg" alt="New Haven Baseball Association Majors 2014" />
            <p>2013 Major League</p>
          </div>
          <div className='team-card'>
            <img src="/images/nhba-minors-2012.jpg" alt="New Haven Baseball Association Minors 2012" />
            <p>2012 Minor League</p>
          </div>
          <div className='team-card'>
            <img src="/images/nhba-minors-2013.jpg" alt="New Haven Baseball Association Minors 2013" />
            <p>2013 Minor League</p>
          </div>

        </div>
      </main>

    </div>
  );
}

export default Community;
