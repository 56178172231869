import Navbar from './components/Navbar';
import './styles/App.scss';
import './styles/Services.scss';
import { Link } from 'react-router-dom';



function Services() {
  return (
    <div className="App">
      <header>
        <Navbar/>      
      </header>
      <main className='services-content'>
        <div className='content-wrapper'>
          <h1>Services</h1>
          <div className='services'>
          <div className='column'>
            <h2>Lawn Services</h2>
            <img src="images/mowing-services.jpg" alt="Freshly cut lawn" />
            <ul>
              <li>Weekly Mowing</li>
              <li>Fertilization</li>
              <li>Weed & Insect Control</li>
              <li>Core Aeration</li>
              <li>Over Seeding</li>
              <li>Edging</li>
            </ul>
          </div>
          <div className='column'>
            <h2>Landscape Services</h2>
            <img src="images/landscape-services.jpg" alt="Mulch, Rock and plant landscaping around a home " />
            <ul>
              <li>Spring & Fall Clean Up</li>
              <li>Landscape Installation (new)</li>
              <li>Landscape Renovation</li>
              <li>Bed Edging</li>
              <li>Mulching</li>
              <li>Brush & Shrub Trimming</li>
            </ul>
          </div>
          <div className='column'>
            <h2>Tree Services</h2>
            <img src="images/tree-services.jpg" alt="Before and After of a tree trimming" />
            <ul>
              <li>Tree Removal</li>
              <li>Tree Trimming</li>
              <li>Stump Removal</li>
              <li>Storm Damage</li>
            </ul>
          </div>
          <div className='column'>
            <h2>Winter Services</h2>
            <img src="images/winter-services.jpg" alt="Truck with a plow in winter" />
            <ul>
              <li>Snow Plowing & Ice Control</li>
            </ul>
            </div>
          </div>
          <h2>Please contact us below to ask us about any services we could provide for you!</h2>
          <Link href="/contact" className='button'>Contact Us Today</Link>
        </div>

      </main>

    </div>
  );
}

export default Services;
