import Navbar from './components/Navbar';
import './styles/App.scss';
import './styles/Gallery.scss';
import Gallery from "react-photo-gallery";
import { photos } from "./data/photos";


function GalleryPage() {
  return (
    <div className="App">
      <header>
        <Navbar/>      
      </header>
      <main className='gallery-content'>
        <h1>Photos of our previous work</h1>
      <Gallery photos={photos} />
      </main>
      <footer>
        
        </footer>
    </div>
  );
}

export default GalleryPage;
